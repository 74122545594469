
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-form {
  ::v-deep {
    .g-field,
    .skeleton {
      &.projectName {
        @include bp-lg-laptop {
          grid-column: 1 / 6;
        }
      }
      &.customerName {
        @include bp-lg-laptop {
          grid-column: 6 / 11;
        }
      }
      &.street {
        @include bp-lg-laptop {
          grid-column: 1 / 5;
        }
      }
      &.city {
        @include bp-lg-laptop {
          grid-column: 5 / 9;
        }
      }
      &.state {
        @include bp-lg-laptop {
          grid-column: 9 / 11;
        }
      }
      &.date {
        @include bp-lg-laptop {
          grid-column: 1 / 5;
        }
        @include bp-xl-desktop {
          grid-column: 1 / 3;
        }
      }
      &.body {
        .input-element {
          min-height: 132px;
        }
      }
    }
  }
}
