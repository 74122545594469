
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.autofill {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.gk-form {
  width: 100%;
  ::v-deep {
    .fields {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 10px;
      align-items: stretch;

      > * {
        grid-column: 1 / 11;
      }
    }
    .image-header {
      margin: 0;
      @include font-horse;

      text-transform: uppercase;
    }
    .actions {
      display: flex;
      align-items: stretch;
      margin-top: 20px;

      @include bp-lg-laptop {
        align-items: center;
      }
      .g-button {
        padding: 10px 25px;
      }
      .g-button,
      .skeleton-button {
        flex: 1;
        justify-content: center;
        @include bp-md-tablet {
          flex: initial;
        }
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}
