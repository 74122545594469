
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-form {
  .padding-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    @include bp-lg-laptop {
      align-items: initial;
      padding: 50px;
      padding-top: 25px;
    }
  }
  .form-title {
    @include font-horse;

    margin-top: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}
