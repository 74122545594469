$family-oswald: 'Oswald', sans-serif;
@mixin family-oswald {
  font-family: $family-oswald;
}
$family-titillium: 'Titillium Web', sans-serif;
@mixin family-titillium {
  font-family: $family-titillium;
}
@mixin character-style($family, $weight: 400, $size, $ls, $lh, $transform: initial) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  line-height: em($size, $lh); // Size, LH
  text-transform: $transform;
  letter-spacing: em($size, $ls); // Size, LS
}

/*
PAGE HEADER
 Oswald- Bold | Size: 30px, LS: 3px, LH: 30px
*/
@mixin font-elephant {
  @include character-style($family: $family-oswald, $weight: bold, $size: 30px, $ls: 3px, $lh: 30px);
}

/*
Subheader
 Titillium- Regular | Size: 22px, LS: 0.55px, LH: 30px
*/
@mixin font-camel {
  @include character-style($family: $family-titillium, $size: 22px, $ls: 0.55px, $lh: 30px);
}

/*
Section Header
 Titillium- Bold | Size: 18px, LS: 0.45px, LH: 22px
*/
@mixin font-horse {
  @include character-style($family: $family-titillium, $weight: bold, $size: 18px, $ls: 0.45px, $lh: 22px);
}

/*
Input Header
 Titillium- SemiBold | Size: 14px, LS: 0.35px, LH: 18px
*/
@mixin font-lion {
  @include character-style($family: $family-titillium, $weight: 600, $size: 14px, $ls: 0.35px, $lh: 18px);
}

/*
Body
 Titillium- Regular | Size: 14px, LS: 0.35px, LH: 18px
*/
@mixin font-adult {
  @include character-style($family: $family-titillium, $size: 14px, $ls: 0.35px, $lh: 18px);
}

/*
Button Text
 Oswald- Regular | Size: 14px, LS: 1.4px, LH: 16px
*/
@mixin font-button {
  @include character-style($family: $family-oswald, $transform: uppercase, $size: 14px, $ls: 1.4px, $lh: 16px);
}

/*
Helper
 Titillium- Regular | Size: 11px, LS: 0.28px, LH: 14px
*/
@mixin font-child {
  @include character-style($family: $family-titillium, $size: 11px, $ls: 0.28px, $lh: 18px);
}

/*
Tags
 Titillium- Regular | Size: 10px, LS: 0.25px, LH: 18px
*/
@mixin font-dog {
  @include character-style($family: $family-titillium, $size: 10px, $ls: 0.25px, $lh: 14px);
}
